import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

import '../design/styles/allies-sections/cover.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const CoverAllies = () => {

    const [cover, setCover] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "allies"]{
                text1,
                "coverImage": image.asset->url
            }`)
            .then((data) => {setCover(data[0])})
            .catch(console.error)
    }, []);

    if (!cover) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.cover-allies.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {
        let backgroundElement = document.querySelector('.block.cover-allies .background');
        if (backgroundElement) {
            let background = backgroundElement.getAttribute('data-background');
            backgroundElement.style.background = 'url(' + background + ')';
        }

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <ParallaxProvider>
            <section className='block cover-allies loading-component' id='portada'>
                <ParallaxBanner
                    layers={[
                        {
                        image: urlFor(cover.coverImage).url(),
                        speed: -20,
                        },
                    ]}
                    style={{ aspectRatio: '2 / 1' }}
                    className='background'
                    data-background={urlFor(cover.coverImage).url()}
                />
                <div className='text-wrapper'>
                    <div className='text-one text-waiting'>
                        <BlockContent
                            blocks={cover.text1}
                            projectId='lohpph99'
                            dataset='production'
                        />
                    </div>
                </div>
            </section>
        </ParallaxProvider>
    )
}

export default CoverAllies;
import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import { Link } from 'react-router-dom';
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

import '../design/styles/about-us-sections/foundations.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const FoundationsAbout = () => {

    const [foundations, setFoundations] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "foundationsAbout"]{
                text1,
                text2,
                image
            }`)
            .then((data) => setFoundations(data[0]))
            .catch(console.error)
    }, []);

    if (!foundations) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.foundations.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <ParallaxProvider>
            <section className='block foundations loading-component' id='fundaciones'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='content'>
                            <div className='flex-content'>
                                <div className='text-wrapper'>
                                    <div className='title text-inview'>Fundaciones</div>
                                    <div className='image-wrapper visible-xs'>
                                        <div className='image image-inview'>
                                            <ParallaxBanner
                                                layers={[
                                                    {
                                                    image: urlFor(foundations.image.asset._ref).url(),
                                                    speed: 10,
                                                    },
                                                ]}
                                                style={{ aspectRatio: '2 / 1' }}
                                                className='background'
                                                data-background={urlFor(foundations.image.asset._ref).url()}
                                            />
                                            <img
                                                src={urlFor(foundations.image.asset._ref).url()}
                                                alt='Imagen Fundaciones'
                                            />
                                        </div>
                                    </div>
                                    <div className='text text-inview'>
                                        <BlockContent
                                            blocks={foundations.text1}
                                            projectId='lohpph99'
                                            dataset='production'
                                        />
                                    </div>
                                    <Link
                                        to='/fundaciones'
                                        className='see-more text-inview'
                                    >
                                        <span className='btn-effect'>{foundations.text2}</span>
                                        <span className='btn-effect'>{foundations.text2}</span>
                                    </Link>
                                </div>
                                <div className='image-wrapper hidden-xs'>
                                    <div className='image image-inview'>
                                        <ParallaxBanner
                                            layers={[
                                                {
                                                image: urlFor(foundations.image.asset._ref).url(),
                                                speed: 10,
                                                },
                                            ]}
                                            style={{ aspectRatio: '2 / 1' }}
                                            className='background'
                                            data-background={urlFor(foundations.image.asset._ref).url()}
                                        />
                                        <img
                                            src={urlFor(foundations.image.asset._ref).url()}
                                            alt='Imagen Fundaciones'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ParallaxProvider>
    )
}

export default FoundationsAbout;
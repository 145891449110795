import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from '@sanity/image-url';
import { NavLink } from 'react-router-dom';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const SingleEvent = ({elem_id, id}) => {

    const [singleEvent, setSingleEvent] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_id == "${elem_id}"]{
                text1,
                text2,
                text3,
                image,
                slug
            }`)
            .then((data) => setSingleEvent(data[0]))
            .catch(console.error)
    }, [elem_id]);

    if (!singleEvent) return <div className="text-loading">Loading...</div>

    let allComponents = document.querySelectorAll('.single-event.loading-component');
    if(allComponents) {
        setTimeout(() => {
            allComponents.forEach(component => {
                
                component.classList.add('ready');
            });
        }, 550);
    }
    setTimeout(() => {

        let allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    return (
        <NavLink
            className='single-event item loading-component'
            to={'/galeria/' + singleEvent.slug.current}
        >
            <div className='image-wrapper'>
                <div className={`image ${(id === 0 || id === 1) ? null : `image-inview`}`}>
                    <img
                        src={urlFor(singleEvent.image.asset._ref).url()}
                        alt={singleEvent.text1}
                    />
                </div>
            </div>
            <div className='text-wrapper'>
                <div className='item-title'>{singleEvent.text1}</div>
                {(singleEvent.text3 != null) ? <div className='year'>{singleEvent.text3}</div> : null}
                <div className='item-desc'>
                    <BlockContent
                        blocks={singleEvent.text2}
                        projectId='lohpph99'
                        dataset='production'
                    />
                </div>
                <div className='see-more'>Ver más</div>
            </div>
        
        </NavLink>
    )
}

export default SingleEvent;
import React from 'react';
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from 'framer-motion';
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import EventsTemplate from './pages/EventsTemplate';
import Gallery from './pages/Gallery';
import Foundations from './pages/Foundations';
import FoundationTemplate from './pages/FoundationTemplate';
import Allies from './pages/Allies';
import AllyTemplate from './pages/AllyTemplate';
import Thanks from './pages/Thanks';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';

//import styles
import './design/styles/base.scss';
import './design/styles/components/navigation.scss';
import './design/styles/components/footer.scss';
import './design/styles/components/form.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";

function App() {
  let location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
      <Routes key={location.pathname} location={location}>
        <Route path='/' element={<Home/>} exact />
        <Route path='/nosotros' element={<AboutUs/>} exact />
        <Route path='/eventos/:slug' element={<EventsTemplate/>} exact />
        <Route path='/galeria/:slug' element={<Gallery/>} exact />
        <Route path='/fundaciones' element={<Foundations/>} exact />
        <Route path='/fundaciones/:slug' element={<FoundationTemplate/>} exact />
        <Route path='/alianzas' element={<Allies/>} exact />
        <Route path='/aliado/:slug' element={<AllyTemplate/>} exact />
        <Route path='gracias' element={<Thanks/>} exact />
        <Route path='aviso-de-privacidad' element={<Privacy/>} exact />
        <Route path="/404" element={<NotFound/>} exact />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
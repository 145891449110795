import React, { useState, useEffect, useRef } from 'react';
import sanityClient from '../client';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import '../design/styles/elements/states-contact.scss';

const Footer = (props) => {

    const form = useRef();

    // state para mandar correo
    const [infoCorreo, guardarInfoCorreo] = useState({
        nombre: '',
        correo: '',
        telefono: '',
        fecha: '',
        mensaje: ''
    });

    // Extrar de infoCorreo
    const {nombre, correo, telefono, fecha, mensaje} = infoCorreo;

    const onChange = e => {
        guardarInfoCorreo({
            ...infoCorreo,
            [e.target.name] : [e.target.value]
        });
    };

    const reset = () => {
        guardarInfoCorreo({
            nombre: '',
            correo: '',
            telefono: '',
            fecha: '',
            mensaje: ''
        });
    };

    const sendEmail = async (e) => {
        e.preventDefault();

        // validar que no haya campos vacíos
        if(nombre.toString().trim() === '' || correo.toString().trim() === '' || telefono.toString().trim() === '' || mensaje.toString().trim() === '' ) {
            return;
        }

        let statusMessage = document.querySelector('.status-message'),
            wrapperForm = document.querySelector('.wrapper-content-form');

        statusMessage.classList.remove('hidden');
        wrapperForm.classList.add('partial-hide');
        statusMessage.innerHTML = 
        `<div class="sending">
            <div class="message">
                <div class="text">Enviando mensaje...</div>
            </div>
        </div>`;
        try {
            const endpoint = `https://www.goplek.com/mailer/send-mail-v1.php`;
            await fetch(endpoint, {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: `data=${JSON.stringify({
                  host: "casablancaeventos.com",
                  data: {
                    nombre: nombre[0],
                    correo: correo[0],
                    telefono: telefono[0],
                    fecha: fecha[0],
                    mensaje: mensaje[0]
                  }
                })}`,
            });
            setTimeout(() => {
                window.location.href = '/gracias'
            }, 3000);
        } catch (error) {
            console.log(error)
            setTimeout(() => {
                statusMessage.innerHTML = '<div class="error">Hubo un error, inténtalo más tarde.</div>';
            }, 3000);
        }
        
        setTimeout(() => {

            statusMessage.classList.add('hidden');
            wrapperForm.classList.remove('partial-hide');

            e.target.reset();
            reset();
        }, 12000);
    }

    let actualLocation = window.location;

    const [footer, setFooter] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "footer"]{
                text1
            }`)
            .then((data) => setFooter(data[0]))
            .catch(console.error)
    }, []);
    
    if (!footer) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.footer.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    return (
        <ParallaxProvider>
            <footer className='footer loading-component' id='contacto'>
                <div className='holder'>
                    <Parallax
                        speed={(window.innerWidth > 767) ? -15 : 0}
                        className='container-fluid'
                    >
                        <div className='header'>
                            <div className='contact-form-wrapper'>
                                <div className='title'>{footer.text1}</div>
                                <form
                                    className='contact-form'
                                    ref={form}
                                    onSubmit={sendEmail}
                                >
                                    <div className="status-message hidden"></div>
                                    <div className="wrapper-content-form">
                                        <div className='inputs-container'>
                                            <input
                                                type='text'
                                                placeholder='Nombre'
                                                name='nombre'
                                                value={nombre}
                                                onChange={onChange}
                                            />
                                            <input
                                                type='text'
                                                placeholder='E-mail'
                                                name='correo'
                                                value={correo}
                                                onChange={onChange}
                                            />
                                            <input
                                                type='text'
                                                placeholder='Teléfono'
                                                name='telefono'
                                                value={telefono}
                                                onChange={onChange}
                                            />
                                            <input
                                                type='date'
                                                placeholder='Fecha de tu evento'
                                                name='fecha'
                                                value={fecha}
                                                onChange={onChange}
                                                required
                                            />
                                        </div>
                                        <div className='inputs-container'>
                                            <textarea
                                                placeholder='Pregúntanos cualquier cosa… estamos aquí para ti.'
                                                name='mensaje'
                                                value={mensaje}
                                                onChange={onChange}
                                            />
                                            <input
                                                type='submit'
                                                value='Enviar'
                                            />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='content'>
                            <div className='flex-content'>
                                <div className='left-side'>
                                    {
                                        (actualLocation.pathname !== '/')
                                            ?
                                                <NavLink
                                                    to='/'
                                                    className='logo'
                                                />
                                            :
                                                <HashLink
                                                    smooth
                                                    to='/#portada'
                                                    className='logo'
                                                />
                                    }
                                    <div className='flex-wrapper'>
                                        <div>
                                            <span className='copyright'>©2023 Carpas Casablanca</span>
                                            <NavLink className='link' to='/aviso-de-privacidad'>Aviso de Privacidad</NavLink>
                                        </div>
                                        <a href='/files/CatalogoPhotobook_Final-comprimido.pdf' target='_blank' className='catalogue desktop'>Descarga nuestro catálogo</a>
                                    </div>
                                </div>
                                <div className='right-side'>
                                    <ul className='list'>
                                        <li className='opt-desktop'>
                                            <HashLink smooth to='/#lo-que-hacemos' className='opt'>Lo que hacemos</HashLink>
                                        </li>
                                        <li className='opt-desktop'>
                                            <HashLink smooth to='/#como-lo-hacemos' className='opt'>Cómo lo hacemos</HashLink>
                                        </li>
                                        <li className='opt-desktop'>
                                            <NavLink className='opt' to='/alianzas'>Aliados</NavLink>
                                        </li>
                                        <li className='opt-desktop'>
                                            <span className='opt'>Proyectos</span>
                                            <ul className='sublist'>
                                                <li>
                                                    <NavLink to='/eventos/social' className='subopt'>Social</NavLink>
                                                </li>
                                                <li style={{ display: 'none' }}>
                                                    <div to='/eventos/empresarial' className='subopt'>Empresarial</div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className='opt-desktop'>
                                            <NavLink to='/nosotros' className='opt'>Nosotros</NavLink>
                                            <ul className='sublist' style={{ display: "none" }}>
                                                <li>
                                                    <HashLink smooth to='/nosotros#proposito' className='subopt'>Propósito</HashLink>
                                                </li>
                                                <li>
                                                    <HashLink smooth to='/nosotros#trayectoria' className='subopt'>Trayectoria</HashLink>
                                                </li>
                                                <li>
                                                    <HashLink smooth to='/nosotros#fundaciones' className='subopt'>Fundaciones</HashLink>
                                                </li>
                                                <li>
                                                    <HashLink smooth to='/nosotros#pilares' className='subopt'>Pilares</HashLink>
                                                </li>
                                            </ul>
                                        </li>
                                        
                                        <li>
                                            <a className='opt' href='https://www.instagram.com/carpascasablanca/?igshid=YmMyMTA2M2Y%3D' target='_blank' rel="noopener noreferrer">IG</a>
                                            
                                        </li>
                                    </ul>
                                    <a href='/files/CatalogoPhotobook_Final-comprimido.pdf' target='_blank' className='catalogue mobile'>Descarga nuestro catálogo</a>
                                </div>
                            </div>
                        </div>
                    </Parallax>
                </div>
            </footer>
        </ParallaxProvider>
    )
}

export default Footer;
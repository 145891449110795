import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import { NavLink } from 'react-router-dom';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import Slider from 'react-slick';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectFade } from "swiper";

import '../design/styles/home-sections/testimonials.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const Testimonials = () => {

    const [testimonials, setTestimonials] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "testimonials"]{
                text1,
                text2,
                sections
            }`)
            .then(
                (data) => {setTestimonials(data[0])}
            )
            .catch(console.error)
    }, []);

    if (!testimonials) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.testimonials.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    const settings = {
        arrows: true,
        cssEase: 'linear',
        dots: false,
        fade: true,
        infinite: true,
        speed: 500
    };

    setTimeout(() => {
        let allItems = document.querySelectorAll('.block.testimonials .carrousel.mobile .item');
        /*if (carrousel) {
            let prev = carrousel.querySelector('.slick-prev'),
                next = carrousel.querySelector('.slick-next'),
                arrowsContainer = document.querySelector('.block.testimonials .arrows-container');
            if (arrowsContainer && prev && next) {
                arrowsContainer.appendChild(prev);
                arrowsContainer.appendChild(next);
            }
        }*/
        let prevBtn = document.querySelector('.block.testimonials .carrousel .slick-prev'),
            nextBtn = document.querySelector('.block.testimonials .carrousel .slick-next');
        allItems.forEach(item => {
            let prev = item.querySelector('.slick-prev-btn'),
                next = item.querySelector('.slick-next-btn');
            prev.addEventListener('click', () => {
                prevBtn.click();
            });
            
            next.addEventListener('click', () => {
                nextBtn.click();
            });
        });

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <section className='block testimonials loading-component'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='header'>
                        <div className='title text-inview'>
                            <BlockContent
                                blocks={testimonials.text1}
                                projectId='lohpph99'
                                dataset='production'
                            />
                        </div>
                    </div>
                    <div className='content'>
                        <div className='carrousel-container'>
                            <Swiper
                                pagination={{
                                    type: "fraction",
                                }}
                                effect={"fade"}
                                navigation={true}
                                modules={[Pagination, Navigation, EffectFade]}
                                className='carrousel desk'
                            >
                                {(testimonials.sections.map((testimonial, index) =>(
                                    <SwiperSlide key={index}>
                                        <div className='item' >
                                            <div className='image-wrapper'>
                                                <div className='image image-inview'>
                                                    <img
                                                        src={urlFor(testimonial.image.asset._ref).url()}
                                                        alt={'Imagen' + testimonial.text1}
                                                    />
                                                </div>
                                            </div>
                                            <div className='text-wrapper'>
                                                <div className='author text-inview'>{testimonial.text2}</div>
                                                <div className='testimonial-text text-inview'>
                                                    <BlockContent
                                                        blocks={testimonial.text1}
                                                        projectId='lohpph99'
                                                        dataset='production'
                                                    />
                                                </div>
                                                <div></div>
                                                
                                            </div>
                                        </div>
                                    </SwiperSlide>  
                                )))}
                            </Swiper>
                            <Slider {...settings} className='carrousel mobile'>
                                {
                                    (testimonials.sections.map((testimonial, index) => (
                                        <div className='item' key={index}>
                                            <div className='image-wrapper'>
                                                <div className='image image-inview'>
                                                    <img
                                                        src={urlFor(testimonial.image.asset._ref).url()}
                                                        alt={'Imagen' + testimonial.text1}
                                                    />
                                                </div>
                                            </div>
                                            <div className='text-wrapper'>
                                                <div className='author text-inview'>{testimonial.text2}</div>
                                                <div className='testimonial-text text-inview'>
                                                    <BlockContent
                                                        blocks={testimonial.text1}
                                                        projectId='lohpph99'
                                                        dataset='production'
                                                    />
                                                </div>
                                                <div></div>
                                                <div className='numbers-wrapper'>
                                                    <div className='slick-prev-btn'></div>
                                                    <div>{index + 1}/{testimonials.sections.length}</div>
                                                    <div className='slick-next-btn'></div>
                                                </div>
                                            </div>
                                        </div>
                                    )))
                                }
                            </Slider>
                            {/*}
                            <div className='arrows-container'></div>
                            {*/}
                        </div>
                        <div className='extra-text'>
                            <div className='text text-inview'>
                                <BlockContent
                                    blocks={testimonials.text2}
                                    projectId='lohpph99'
                                    dataset='production'
                                />
                            </div>
                            <NavLink
                                to='/nosotros'
                                className='btn text-inview'
                            >
                                <span className='btn-effect'>Adéntrate en nuestra historia</span>
                                <span className='btn-effect'>Adéntrate en nuestra historia</span>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials;
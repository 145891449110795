import React from 'react';
import { NavLink } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { motion, useIsPresent } from 'framer-motion';

import '../design/styles/components/thanks.scss';

const Thanks = () => {

    const isPresent = useIsPresent();

    return (
        <div className='outer-wrapper'>
            <Navigation/>
            <section className='block thanks'>
                <div className='holder'>
                    <div className='text'>Gracias por escribirnos, ¡nuestro equipo estará en contacto contigo pronto!</div>
                    <NavLink to="/" className="link-inicio" >Inicio</NavLink>
                </div>
            </section>
            <motion.div
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0, transition: { duration: 1.2, ease: "circOut" } }}
                exit={{ scaleY: 1, transition: { duration: 1.2, ease: "circIn" } }}
                style={{ originY: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    )
}

export default Thanks
import React, {useState, useEffect} from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import '../design/styles/home-sections/experience.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const ExperienceHome = () => {

    const [experiences, setExperiences] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "experiences"]{
                text,
                "socialImage": image1.asset->url,
                "companyImage": image2.asset->url
            }`)
            .then((data) => setExperiences(data[0]))
            .catch(console.error)
    }, []);

    if (!experiences) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.experiences-home.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <section className='block experiences-home loading-component' id='lo-que-hacemos'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='content'>
                        <div className='text-wrapper'>
                            <div className='text text-inview'>
                                <BlockContent
                                    blocks={experiences.text}
                                    projectId='lohpph99'
                                    dataset='production'
                                />
                                <HashLink
                                    smooth
                                    to='/#contacto'
                                    className='btn text-waiting'
                                >
                                    <span className='btn-effect'>Platícanos las ideas para tu evento</span>
                                    <span className='btn-effect'>Platícanos las ideas para tu evento</span>
                                </HashLink>
                            </div>
                        </div>
                        <div className='images-wrapper'>
                            <NavLink
                                className='item'
                                to='eventos/social'
                            >
                                <div className='title text-inview'>Social</div>
                                <div className='image image-inview'>
                                    <img
                                        src={urlFor(experiences.socialImage).url()}
                                        alt="Imagen Social"
                                    />
                                </div>
                            </NavLink>
                            <div
                                className='item'
                                to='eventos/empresarial'
                            >
                                <div className='title text-inview'>Empresarial</div>
                                <div className='image image-inview'>
                                    <img
                                        src={urlFor(experiences.companyImage).url()}
                                        alt="Imagen Empresarial"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExperienceHome;
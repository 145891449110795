import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import { NavLink } from 'react-router-dom';
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from '@sanity/image-url';
import PrevBtn from './PrevBtn';
import NextBtn from './NextBtn';
import useSingleAlly from '../customHooks/useSingleAlly';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const GalleryAllies = ({slug}) => {

    let indexPrev = 0,
        indexNext = 0;

    let alliesSlug = 'allies';

    //const [ally, setAlly] = useState(null);
    let ally = useSingleAlly(slug)

    const [allAllies, setAllAllies] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        window.scrollTo(0, 0)
        
        sanityClient
            .fetch(`*[_type == "${alliesSlug}"][0]{
                sections
            }`)
            .then((data) =>{
                setAllAllies(data)
                setIsLoading(false)
            })
            .catch(console.error)
            // eslint-disable-next-line
    }, [alliesSlug]);
    
    if (!ally) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.gallery-allies.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }
    
    setTimeout(() => {
    
        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    if(!isLoading){
        allAllies.sections.map((sect, index) => {
            if (ally._id === sect._ref) {
                indexPrev = index - 1;
                indexNext = index + 1;
            }
            return <div className='hidden'>Working</div>
        })
    }

    return (
        <ParallaxProvider>
            <section className='block gallery-allies'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title'>{ally.text1}</div>
                            <div className='text-wrapper'>
                                <div className='text-one'>
                                    <BlockContent
                                        blocks={ally.text3}
                                        projectId='lohpph99'
                                        dataset='production'
                                    />
                                </div>
                                <div className='text-two'>
                                    <BlockContent
                                        blocks={ally.text4}
                                        projectId='lohpph99'
                                        dataset='production'
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='content'>
                            <Parallax
                                speed={(window.innerWidth > 767) ? -10 : 0}
                                className='image'
                            >
                                <img
                                    src={urlFor(ally.gallery[0].asset._ref).url()}
                                    alt={ally.text1}
                                />
                            </Parallax>
                            <div className='images-wrapper-one text-waiting'>
                                <Parallax
                                    speed={(window.innerWidth > 767) ? -10 : 0}
                                    className='image-one'
                                >
                                    <img
                                        src={urlFor(ally.gallery[1].asset._ref).url()}
                                        alt={ally.text1}
                                    />
                                </Parallax>
                                <Parallax
                                    speed={(window.innerWidth > 767) ? -15 : 0}
                                    className='image-two'
                                >
                                    <img
                                        src={urlFor(ally.gallery[2].asset._ref).url()}
                                        alt={ally.text1}
                                    />
                                </Parallax>
                            </div>
                            <div className='images-wrapper-two text-inview'>
                                <div className='image-three'>
                                    <img
                                        src={urlFor(ally.gallery[3].asset._ref).url()}
                                        alt={ally.text1}
                                    />
                                </div>
                                <div className='image-four'>
                                    <img
                                        src={urlFor(ally.gallery[4].asset._ref).url()}
                                        alt={ally.text1}
                                    />
                                </div>
                            </div>
                            <div className='images-wrapper-one text-inview'>
                                <Parallax
                                    speed={(window.innerWidth > 767) ? -10 : 0}
                                    className='image-one'
                                >
                                    <img
                                        src={urlFor(ally.gallery[5].asset._ref).url()}
                                        alt={ally.text1}
                                    />
                                </Parallax>
                                <Parallax
                                    speed={(window.innerWidth > 767) ? -15 : 0}
                                    className='image-two'
                                >
                                    <img
                                        src={urlFor(ally.gallery[6].asset._ref).url()}
                                        alt={ally.text1}
                                    />
                                </Parallax>
                            </div>
                            <div className='images-wrapper-two text-inview'>
                                <div className='image-three'>
                                    <img
                                        src={urlFor(ally.gallery[7].asset._ref).url()}
                                        alt={ally.text1}
                                    />
                                </div>
                                <div className='image-four'>
                                    <img
                                        src={urlFor(ally.gallery[8].asset._ref).url()}
                                        alt={ally.text1}
                                    />
                                </div>
                            </div>
                            <div className='extra-text'>
                                <div className='contact'>
                                    <div className='name'>Contacto</div>
                                    <div className='txt'>
                                        <BlockContent
                                            blocks={ally.text5}
                                            projectId='lohpph99'
                                            dataset='production'
                                        />
                                    </div>
                                </div>
                                <div className='location'>
                                    <div className='name'>Ubicación</div>
                                    <div className='txt'>
                                        <BlockContent
                                            blocks={ally.text6}
                                            projectId='lohpph99'
                                            dataset='production'
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                (allAllies.sections)
                                ?
                                    <div className='flex-content prev-next-content'>
                                        {
                                            (indexPrev > -1)
                                                ?
                                                    <PrevBtn
                                                        elem_id={allAllies.sections[indexPrev]._ref}
                                                    />
                                                :
                                                    <PrevBtn
                                                        elem_id={allAllies.sections[allAllies.sections.length-1]._ref}
                                                    />
                                        }
                                        <NavLink
                                            className='current'
                                            to={'/alianzas'}
                                        >
                                            Aliados
                                        </NavLink>
                                        {
                                            (indexNext < allAllies.sections.length)
                                                ?
                                                    <NextBtn
                                                        elem_id={allAllies.sections[indexNext]._ref}
                                                    />
                                                :
                                                    <NextBtn
                                                        elem_id={allAllies.sections[0]._ref}
                                                    />
                                        }
                                    </div>
                                :
                                    null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </ParallaxProvider>
    );
}

export default GalleryAllies;
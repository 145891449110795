import React from 'react';
import Navigation from '../components/Navigation';
import ContentSingleFoundation from '../componentsFoundations/ContentSingleFoundation';
import Footer from '../components/Footer';

import '../design/styles/foundations-sections/cover-template.scss';
import '../design/styles/foundations-sections/first-section.scss';
import '../design/styles/foundations-sections/second-section.scss';
import { motion, useIsPresent } from 'framer-motion';

const FoundationTemplate = () => {
    const isPresent = useIsPresent();

    return (
        <div className='outer-wrapper'>
            <div className='general-shape'></div>
            <Navigation/>
            <ContentSingleFoundation/>
            <Footer/>
            <motion.div
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleY: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originY: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    )
}

export default FoundationTemplate;
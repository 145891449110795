import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from '@sanity/image-url';
import { NavLink } from 'react-router-dom';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const SingleAlly = ({elem_id}) => {

    const [singleAlly, setSingleAlly] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_id == "${elem_id}"]{
                text1,
                text2,
                text3,
                image,
                slug
            }`)
            .then((data) => setSingleAlly(data[0]))
            .catch(console.error)
    }, [elem_id]);

    if (!singleAlly) return <div className="text-loading">Loading...</div>

    let allComponents = document.querySelectorAll('.single-ally.loading-component');
    if(allComponents) {
        setTimeout(() => {
            allComponents.forEach(component => {
                
                component.classList.add('ready');
            });
        }, 550);
    }

    setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <NavLink
            className='single-ally item loading-component'
            to={'/aliado/' + singleAlly.slug.current}
        >
            <div className='image-wrapper'>
                <div className='image image-inview'>
                    <img
                        src={urlFor(singleAlly.image.asset._ref).url()}
                        alt={singleAlly.text1}
                    />
                </div>
            </div>
            <div className='text-wrapper text-waiting'>
                <div className='item-title'>{singleAlly.text1}</div>
                <div className='item-desc'>
                    <BlockContent
                        blocks={singleAlly.text2}
                        projectId='lohpph99'
                        dataset='production'
                    />
                </div>
                <div className='see-more'>Ver más</div>
            </div>
        
        </NavLink>
    )
}

export default SingleAlly;
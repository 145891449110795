import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import { NavLink } from 'react-router-dom';

const NextBtn = ({elem_id}) => {

    const [singleEvent, setSingleEvent] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_id == "${elem_id}"]{
                text1,
                slug
            }`)
            .then((data) => setSingleEvent(data[0]))
            .catch(console.error)
    }, [elem_id]);

    if (!singleEvent) return <div className="text-loading">Loading...</div>

    return (
        <NavLink
            to={'/galeria/' + singleEvent.slug.current}
            className='next'
        >
            <span>Siguiente</span>
            <span className='next-name'>{singleEvent.text1}</span>
        </NavLink>
    );
}

export default NextBtn;
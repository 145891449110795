import { useEffect, useState } from 'react'
import SanityClient from '../client'

export default function useSingleEvent(slug){
    const [ event, setEvent ] = useState()
    useEffect(() =>{
        SanityClient.fetch(`*[slug.current == "${slug}"][0]{
                _id,
                text1,
                text3,
                text4,
                gallery,
                video,
                slug
            }`)
            .then((data) =>{
                setEvent(data)
            })
            .catch(console.error);
    }, [slug])

    return event
}
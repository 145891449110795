import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import SingleAlly from './SingleAlly';
import BlockContent from "@sanity/block-content-to-react";

import '../design/styles/allies-sections/grid.scss';

const AllAllies = () => {

    const [ally, setAlly] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "allies"]{
                text2,
                sections
            }`)
            .then((data) => {setAlly(data[0])})
            .catch(console.error)
    }, []);

    if (!ally) {return <div className="text-loading">Loading...</div>}

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
    }, 1000);

    return (
        <section className='block allies-grid'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='header'>
                        <div className='title text-inview'>Aliados</div>
                        <div className='text text-inview'>
                            <BlockContent
                                blocks={ally.text2}
                                projectId='lohpph99'
                                dataset='production'
                            />
                        </div>
                    </div>
                    <div className='content'>
                        <div className='grid'>
                            {ally.sections.map(elem => (
                                <SingleAlly
                                    elem_id={elem._ref}
                                    key={elem._ref}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AllAllies;
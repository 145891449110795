import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import BlockContent from "@sanity/block-content-to-react";
import video from '../design/imgs/cover-casablanca-new.mp4';

import '../design/styles/home-sections/cover.scss';

const CoverHome = () => {

    const [cover, setCover] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "coverHome"]{
                text1,
                text2,
                "coverImage": image.asset->url
            }`)
            .then((data) => {
                setCover(data[0])
            })
            .catch(console.error)
    }, []);

    if (!cover) {
        return <div className="text-loading">Loading...</div>
    } else {
        setTimeout(() => {
            let component = document.querySelector('.block.cover-home.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    let backgroundElement = document.querySelector('.block.cover-home .background');

    if (backgroundElement) {
        let background = backgroundElement.getAttribute('data-background');
        backgroundElement.style.background = 'url(' + background + ')';
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return(
        <section className='block cover-home loading-component' id='portada'>
            <video src={video} loop muted autoPlay playsInline></video>
            <div className='holder'>
                <div className='content'>
                    <div className='text-wrapper'>
                        <div className='text text-waiting'>
                            <BlockContent
                                blocks={cover.text1}
                                projectId='lohpph99'
                                dataset='production'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CoverHome;
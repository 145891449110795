import React from 'react'
import Navigation from '../components/Navigation';
import { motion, useIsPresent } from 'framer-motion';

import '../design/styles/components/privacy.scss';

const Privacy = () => {

    const isPresent = useIsPresent();

    return (
        <div className='outer-wrapper'>
            <Navigation/>
            <section className='block privacy'>
                <div className='holder'>
                    <div className='text'>
                        Carpas Casablanca S. A. de C. V. con domicilio en Calle José María Vigil 147, Escandón I Secc, Miguel Hidalgo, 11800 Ciudad de México, CDMX, conforme a la establecido en la Ley Federal de Protección de Datos en Posesión de
Particlares, pone a disposición de nuestros clientes, proveedores, empleados y público en general, nuestro Aviso de Privacidad. 
Los datos personales que nos proporciona son utilizados estrictamente en la realización de funciones propias de nuestra empresa y por ningún motivo serán transferidos a terceros.
                    </div>
                </div>
            </section>
            <motion.div
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0, transition: { duration: 1.2, ease: "circOut" } }}
                exit={{ scaleY: 1, transition: { duration: 1.2, ease: "circIn" } }}
                style={{ originY: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    );
}

export default Privacy;
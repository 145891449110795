import React from 'react'
import { NavLink } from 'react-router-dom';
import Navigation from '../components/Navigation';
import { motion, useIsPresent } from 'framer-motion';

const NotFound = () => {

    const isPresent = useIsPresent();

    return (
        <div className='outer-wrapper'>
            <Navigation/>
            <section className='block not-found'>
                <div className='holder'>
                    <div className='text-wrapper'>
                        <div className='text'><h1>Página no encontrada.</h1></div>
                        <NavLink to="/" className="link-inicio" >Ir al Inicio</NavLink>
                    </div>
                </div>
            </section>
            <motion.div
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0, transition: { duration: 1.2, ease: "circOut" } }}
                exit={{ scaleY: 1, transition: { duration: 1.2, ease: "circIn" } }}
                style={{ originY: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    );
}

export default NotFound;
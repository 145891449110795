import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import BlockContent from "@sanity/block-content-to-react";

import '../design/styles/about-us-sections/banner.scss';

const BannerAbout = () => {

    const [banner, setBanner] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "bannerAbout"]{
                text1,
                "bannerImage": image.asset->url
            }`)
            .then((data) => {setBanner(data[0])})
            .catch(console.error)
    }, []);

    if (!banner) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.banner.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {

        let backgroundElement = document.querySelector('.block.banner .background');
        if (backgroundElement) {
            let background = backgroundElement.getAttribute('data-background');
            backgroundElement.style.background = 'url(' + background + ')';
        }
    }, 1000);

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <section className='block banner loading-component'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='content'>
                        <div className='text-wrapper'>
                            <div className='text text-inview'>
                                <BlockContent
                                    blocks={banner.text1}
                                    projectId='lohpph99'
                                    dataset='production'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BannerAbout;
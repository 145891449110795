import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import { NavLink } from 'react-router-dom';

const NextBtn = ({elem_id}) => {

    const [singleAlly, setSingleAlly] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_id == "${elem_id}"]{
                text1,
                slug
            }`)
            .then((data) => setSingleAlly(data[0]))
            .catch(console.error)
    }, [elem_id]);

    if (!singleAlly) return <div className="text-loading">Loading...</div>

    return (
        <NavLink
            to={'/aliado/' + singleAlly.slug.current}
            className='next'
        >
            <span>Siguiente</span>
            <span className='next-name'>{singleAlly.text1}</span>
        </NavLink>
    );
}

export default NextBtn;
import React from 'react';
import CoverFoundations from '../componentsFoundations/CoverFoundations';
import GridFoundations from '../componentsFoundations/GridFoundations';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { motion, useIsPresent } from 'framer-motion';

const Foundations = () => {
    const isPresent = useIsPresent();

    return (
        <div className='outer-wrapper'>
            <div className='general-shape'></div>
            <Navigation/>
            <CoverFoundations/>
            <GridFoundations/>
            <Footer/>
            <motion.div
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleY: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originY: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    )
}

export default Foundations;
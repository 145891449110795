import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import BlockContent from "@sanity/block-content-to-react";
import imageUrlBuilder from '@sanity/image-url';
import { NavLink } from 'react-router-dom';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const SingleFoundation = ({elem_id}) => {

    const [singleFoundation, setSingleFoundation] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_id == "${elem_id}"]{
                text1,
                text2,
                text3,
                text4,
                image,
                image1,
                slug
            }`)
            .then((data) => setSingleFoundation(data[0]))
            .catch(console.error)
    }, [elem_id]);

    if (!singleFoundation) return <div className="text-loading">Loading...</div>

    return (
        <NavLink
            className={(singleFoundation.image1 && singleFoundation.text3 && singleFoundation.text4) ? 'item link single-foundation loading-component' : 'item single-foundation loading-component'}
            to={(singleFoundation.image1 && singleFoundation.text3 && singleFoundation.text4) ? '/fundaciones/' + singleFoundation.slug.current : '/fundaciones'}
        >
            <div className='image-wrapper'>
                <div className='image'>
                    <img
                        src={urlFor(singleFoundation.image).url()}
                        alt={singleFoundation.text1}
                    />
                </div>
            </div>
            <div className='text-wrapper'>
                <div className='title'>{singleFoundation.text1}</div>
                <div className='desc'>
                    <BlockContent
                        blocks={singleFoundation.text2}
                        projectId='lohpph99'
                        dataset='production'
                    />
                </div>
                {
                    (singleFoundation.image1 && singleFoundation.text3 && singleFoundation.text4)
                        ?
                            <div className='see-more'>Ver más</div>
                        :
                            null
                }
            </div>
        </NavLink>
    )
}

export default SingleFoundation;
import React from 'react';
import CoverAbout from '../componentsAbout/CoverAbout';
import PurposeAbout from '../componentsAbout/PurposeAbout';
import TrajectoryAbout from '../componentsAbout/TrajectoryAbout';
import FoundationsAbout from '../componentsAbout/FoundationsAbout';
import PillarsAbout from '../componentsAbout/PillarsAbout';
import BannerAbout from '../componentsAbout/BannerAbout';
//import AlliesAbout from '../componentsAbout/AlliesAbout';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { motion, useIsPresent } from 'framer-motion';

const AboutUs = () => {
    const isPresent = useIsPresent();
    let location = window.location;
    if(location.search !== '') {
        setTimeout(() => {
            let hash = location.search.slice(1),
                section = document.getElementById(hash),
                allTexts = section.querySelectorAll('.text-inview'),
                allImages = section.querySelectorAll('.image-inview'),
                sectionRect = section.getBoundingClientRect();
            setTimeout(() => {
                window.scrollTo(0, sectionRect.top)
            }, 350);
            allTexts.forEach(text => {
                setTimeout(() => {
                    if(!text.classList.contains('viewed')) {
                        text.classList.add('viewed');
                    }
                }, 350);
            });
            allImages.forEach(image => {
                setTimeout(() => {
                    if(!image.classList.contains('viewed')) {
                        image.classList.add('viewed');
                    }
                }, 350);
            });
        }, 350);
    }

    return(
        <div className='outer-wrapper'>
            <div className='general-shape'></div>
            <Navigation/>
            <CoverAbout/>
            <PurposeAbout/>
            <TrajectoryAbout/>
            <FoundationsAbout/>
            <PillarsAbout/>
            <BannerAbout/>
            <Footer/>
            <motion.div
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0, transition: { duration: 1.2, ease: "circOut" } }}
                exit={{ scaleY: 1, transition: { duration: 1.2, ease: "circIn" } }}
                style={{ originY: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    )
}

export default AboutUs;
import React from 'react';
import CoverHome from '../components/CoverHome';
import ExperienceHome from '../components/ExperienceHome';
import ProductsHome from '../components/ProductsHome';
import HowWeDoIt from '../components/HowWeDoIt';
import Gallery from '../components/Gallery';
import Testimonials from '../components/Testimonials';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import Loader from '../components/Loader';
import { motion, useIsPresent } from 'framer-motion';


const Home = () => {
    const isPresent = useIsPresent();
    let location = window.location,
        body = document.querySelector('body');
    body.classList.add('home')
    if(location.search !== '') {
        setTimeout(() => {
            let hash = location.search.slice(1),
                section = document.getElementById(hash),
                allTexts = section.querySelectorAll('.text-inview'),
                allImages = section.querySelectorAll('.image-inview'),
                sectionRect = section.getBoundingClientRect();
            setTimeout(() => {
                window.scrollTo(0, sectionRect.top)
            }, 350);
            allTexts.forEach(text => {
                setTimeout(() => {
                    if(!text.classList.contains('viewed')) {
                        text.classList.add('viewed');
                    }
                }, 350);
            });
            allImages.forEach(image => {
                setTimeout(() => {
                    if(!image.classList.contains('viewed')) {
                        image.classList.add('viewed');
                    }
                }, 350);
            });
        }, 350);
    }
    return(
            <div className='outer-wrapper'>
                <Loader/>
                <Navigation/>
                <CoverHome/>
                <ExperienceHome/>
                <ProductsHome/>
                <HowWeDoIt/>
                <Gallery/>
                <Testimonials/>
                <Footer/>
                <motion.div
                    initial={{ scaleY: 1 }}
                    animate={{ scaleY: 0, transition: { duration: 1.2, ease: "circOut" } }}
                    exit={{ scaleY: 1, transition: { duration: 1.2, ease: "circIn" } }}
                    style={{ originY: isPresent ? 0 : 1 }}
                    className="privacy-screen"
                />
            </div>
    )
}

export default Home;
import { useEffect, useState } from 'react'
import SanityClient from '../client'

export default function useSingleAlly(slug){
    const [ ally, setAlly ] = useState()
    useEffect(() =>{
        SanityClient
            .fetch(`*[slug.current == "${slug}"][0]{
                _id,
                text1,
                text3,
                text4,
                text5,
                text6,
                slug,
                gallery
            }`)
            .then((data) =>{
                setAlly(data)
            })
            .catch(console.error)
    }, [slug])

    return ally
}
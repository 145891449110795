import React, { useState, useEffect } from 'react';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { useParams } from 'react-router-dom';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import { NavLink } from 'react-router-dom';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const ContentSingleFoundation = () => {

    const [foundation, setFoundation] = useState(null);
    const { slug } = useParams();

    useEffect(() => {
        sanityClient
            .fetch(`*[slug.current == "${slug}"]{
                text1,
                text3,
                text4,
                text5,
                image1,
                image2, 
                image3,
                image4,
                image5,
                image6,
                slug
            }`)
            .then((data) => {setFoundation(data[0])})
            .catch(console.error)
    }, [slug]);

    if (!foundation) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.content-single-foundation.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {
        let backgroundElement = document.querySelector('.background');
        if (backgroundElement) {
            let background = backgroundElement.getAttribute('data-background');
            backgroundElement.style.background = 'url(' + background + ')';
        }

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <ParallaxProvider>
            <div className='content-single-foundation loading-component'>
            <section className='block cover-foundation-template'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='content'>
                            <div className='text-wrapper'>
                                <div className='title text-waiting'>{foundation.text1}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                (foundation.image1 && foundation.text3 && foundation.text4)
                    ?
                    <section className='block first-section-foundation'>
                        <div className='holder'>
                            <div className='container-fluid'>
                                <div className='content'>
                                    <div className='flex-content'>
                                        <div className='image-wrapper'>
                                            <div className='image image-waiting'>
                                                <img
                                                    src={urlFor(foundation.image1).url()}
                                                    alt='Imagen uno'
                                                />
                                            </div>
                                            <div
                                                className='background'
                                                data-background={urlFor(foundation.image1).url()}
                                            />
                                        </div>
                                        <div className='text-wrapper'>
                                            <div className='text-one text-waiting'>
                                                <BlockContent
                                                    blocks={foundation.text3}
                                                    projectId='lohpph99'
                                                    dataset='production'
                                                />
                                            </div>
                                            <div className='text-two text-inview'>
                                                <BlockContent
                                                    blocks={foundation.text4}
                                                    projectId='lohpph99'
                                                    dataset='production'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                        null
            }
            {
                (foundation.text5 && foundation.image2 && foundation.image3 && foundation.image4 && foundation.image5 && foundation.image6)
                    ?
                        <section className='block second-section-foundation'>
                            <div className='holder'>
                                <div className='container-fluid'>
                                    <div className='header'>
                                        <div className='text-wrapper'>
                                            <div className='text text-inview'>
                                                <BlockContent
                                                    blocks={foundation.text5}
                                                    projectId='lohpph99'
                                                    dataset='production'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='content'>
                                        <div className='images-wrapper-one'>
                                            <Parallax
                                                speed={(window.innerWidth > 767) ? 10 : 0}
                                                className='image-one '
                                            >
                                                <img
                                                    src={urlFor(foundation.image2).url()}
                                                    alt='Imagen Uno'
                                                />
                                            </Parallax>
                                            <Parallax
                                                speed={(window.innerWidth > 767) ? -10 : 0}
                                                className='image-two '
                                            >
                                                <img
                                                    src={urlFor(foundation.image3).url()}
                                                    alt='Imagen Dos'
                                                />
                                            </Parallax>
                                        </div>
                                        <div className='image-three '>
                                            <img
                                                src={urlFor(foundation.image4).url()}
                                                alt='Imagen Tres'
                                            />
                                        </div>
                                        <div className='images-wrapper-two'>
                                            <Parallax
                                                speed={(window.innerWidth > 767) ? 10 : 0}
                                                className='image-four '
                                            >
                                                <img
                                                    src={urlFor(foundation.image5).url()}
                                                    alt='Imagen Cuatro'
                                                />
                                            </Parallax>
                                            <Parallax
                                                speed={(window.innerWidth > 767) ? -5 : 0}
                                                className='image-five '
                                            >
                                                <img
                                                    src={urlFor(foundation.image6).url()}
                                                    alt='Imagen Cinco'
                                                />
                                            </Parallax>
                                        </div>
                                        <NavLink
                                            to='/fundaciones'
                                            className='see-more text-inview'
                                        >
                                            Fundaciones
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </section>
                    :
                        null
            }
            </div>
        </ParallaxProvider>
    )

}

export default ContentSingleFoundation;
import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

import '../design/styles/about-us-sections/pillars.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const PillarsAbout = () => {

    const [pillars, setPillars] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "pillarsAbout"]{
                text1,
                sections
            }`)
            .then((data) => setPillars(data[0]))
            .catch(console.error)
    }, []);

    if (!pillars) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.pillars.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <ParallaxProvider>
            <section className='block pillars loading-component' id='pilares'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title text-inview'>
                                <BlockContent
                                    blocks={pillars.text1}
                                    projectId='lohpph99'
                                    dataset='production'
                                />
                            </div>
                        </div>
                        <div className='content'>
                            <div className='flex-content'>
                                {pillars.sections.map(pilar => (
                                    <div className='item' key={pilar.text1}>
                                        <div className='image-wrapper'>
                                            <div className='image image-inview'>
                                                <ParallaxBanner
                                                    layers={[
                                                        {
                                                        image: urlFor(pilar.image.asset._ref).url(),
                                                        speed: -10,
                                                        },
                                                    ]}
                                                    style={{ aspectRatio: '2 / 1' }}
                                                    className='background'
                                                    data-background={urlFor(pilar.image.asset._ref).url()}
                                                />
                                                <img
                                                    src={urlFor(pilar.image.asset._ref).url()}
                                                    alt='pilar.text1'
                                                />
                                            </div>
                                        </div>
                                        <div className='item-title text-inview'>{pilar.text1}</div>
                                        <div className='item-desc text-inview'>
                                            <BlockContent
                                                blocks={pilar.text2}
                                                projectId='lohpph99'
                                                dataset='production'
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ParallaxProvider>
    )
}

export default PillarsAbout;
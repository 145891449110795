import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import Slider from 'react-slick';
//import { Swiper, SwiperSlide } from "swiper/react"
//import { EffectFade, Navigation, Pagination } from "swiper" 

import '../design/styles/home-sections/gallery.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

function SampleNextArrow(props){
    const { className, style, onClick } = props
    return(
        <div 
            className={className}
            style={{ ...style, display: "block", position: "absolute", top: "110%", right: "157px", width: "auto", height: "auto" }}
            onClick={onClick}
        />
    )
}

function SamplePrevArrow(props){
    const { className, style, onClick } = props
    return(
        <div 
            className={className}
            style={{ ...style, display: "block", position: "absolute", top: "110%", left: "158px", width: "auto", height: "auto" }}
            onClick={onClick}
        />
    )
}

const Gallery = () => {

    const [galleryHome, setGallery] = useState(null);
    let copiarArray = [];
    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "gallery"]{
                text1,
                text2,
                gallery
            }`)
            .then((data) => {setGallery(data[0]);})
            .catch(console.error)
            
    }, []);

    if (!galleryHome) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.gallery-home.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() =>{
        let prueba = document.querySelector('.block.gallery-home .carrousel [data-index="0"]')
        prueba.classList.add('slick-now')
        let prueba2 = document.querySelector('.block.gallery-home .carrousel [data-index="16"]')
        prueba2.classList.add('slick-now')
    })
    const settings = {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        focusOnSelect: true,
        speed: 1000,
        infinite: true,
        initialSlide: 16,
        beforeChange: (current, next) =>{
            const prevSlideElement = document.querySelector(`.block.gallery-home .carrousel [data-index="${current}"]`);
            const nextSlideElement = document.querySelector(`.block.gallery-home .carrousel [data-index="${next}"]`);
            setTimeout(() => {
                prevSlideElement.classList.remove('slick-now');
                nextSlideElement.classList.add('slick-now');
            });
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    nextArrow: <SampleNextArrow />,
                    prevArrow: <SamplePrevArrow />
                }
            }
        ]
    }
    setTimeout(() => {

        let carrouselGallery = document.querySelector('.block.gallery-home .carrousel');

        if (carrouselGallery) {
            let prev = carrouselGallery.querySelector('.slick-prev'),
                next = carrouselGallery.querySelector('.slick-next'),
                cursor = document.querySelector('.block.gallery-home .cursor');
    
            prev.addEventListener('mousemove', function(e){
                cursor.classList.add('cursor-prev');
                var x = e.clientX;
                var y = e.clientY;
                cursor.style.left = x + 'px';
                cursor.style.top = y + 'px';
            });
    
            next.addEventListener('mousemove', function(e){
                cursor.classList.add('cursor-next');
                var x = e.clientX;
                var y = e.clientY;
                cursor.style.left = x + 'px';
                cursor.style.top = y + 'px';
            });
    
            prev.addEventListener('mouseleave', function(e){
                cursor.classList.remove('cursor-prev');
            });
    
            next.addEventListener('mouseleave', function(e){
                cursor.classList.remove('cursor-next');
            });
        }

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
    }, 1000);

    setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);
    function Duplicar(array){
        for(let x = 0; x<9; x++){
            for(let i = 0; i < array.length; i++){
                copiarArray.push(array[i])
            }
        }
    }
    Duplicar(galleryHome.gallery)
    return (
        <section className='block gallery-home loading-component'>
            <div className="cursor"></div>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='header'>
                        <div className='title text-inview'>{galleryHome.text1}</div>
                        <div className='desc text-inview'>{galleryHome.text2}</div>
                    </div>
                    <div className='content'>
                        {/*
                        <Swiper
                            slidesPerView={2}
                            slidesPerGroupSkip={1}
                            navigation={true}
                            loop={true}
                            spaceBetween={30}
                            modules={[EffectFade, Navigation, Pagination]}
                            className='carrousel'
                        >
                            {galleryHome.gallery.map((image, index) => (
                                <SwiperSlide>
                                    <div className='item' key={index}>
                                        <div className='image-wrapper'>
                                            <div className='image image-inview'>
                                                <img
                                                    src={urlFor(image.asset._ref).url()}
                                                    alt={'Imagen ' + index}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        */}
                        <Slider {...settings} className='carrousel'>
                            {copiarArray.map((image, index) => (
                                <div className='slide' key={index}>
                                    <img
                                        src={urlFor(image.asset._ref).url()}
                                        alt={'Imagen ' + index}
                                     />
                                </div>
                            ))}
                        </Slider>
                        {/*
                        <Slider {...settings} className='carrousel'>
                            {galleryHome.gallery.map((image, index) => (
                                <div className='item' key={index}>
                                    <div className='image-wrapper'>
                                        <div className='image image-inview'>
                                            <img
                                                src={urlFor(image.asset._ref).url()}
                                                alt={'Imagen ' + index}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                        */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Gallery;
import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import SingleEvent from '../components/SingleEvent';
//import { NavLink } from 'react-router-dom';

const SingleEvents = ({slug}) => {

    const [event, setEvent] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0)
        sanityClient
            .fetch(`*[slug.current == "${slug}"]{
                text1,
                text2,
                slug,
                sections
            }`)
            .then((data) => setEvent(data[0]))
            .catch(console.error)
    }, [slug]);

    if (!event) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.event.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    localStorage.setItem('currentEvento', event.slug.current);

    return(
        <section className='block event loading-component' id='evento'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='header'>
                        <div className='title text-waiting'>{event.text1}</div>
                        <div className='desc text-waiting'>{event.text2}</div>
                    </div>
                    <div className='content'>
                        <div className='grid'>
                            {event.sections.map((elem, index) => (
                                <SingleEvent
                                    elem_id={elem._ref}
                                    key={elem._ref}
                                    id={index}
                                />
                            ))}
                        </div>
                        {/*
                            (event.text1 === 'Social')
                                ?
                                    <NavLink
                                        className='gplk-link text-inview'
                                        to='/eventos/empresarial'
                                    >
                                        Eventos Empresariales</NavLink>
                                :
                                    <NavLink
                                        className='gplk-link text-inview'
                                        to='/eventos/social'
                                    >
                                        Eventos Sociales</NavLink>
                        */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SingleEvents;
import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";

import '../design/styles/home-sections/how-we-do-it.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const HowWeDoIt = () => {

    const [section, setSection] = useState(null);
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });

    const setDimension = () => {
        getDimension({
          dynamicWidth: window.innerWidth,
          dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {

        sanityClient
            .fetch(`*[_type == "howWeDoIt"]{
                text1,
                image1,
                sections,
                text2,
                image2
            }`)
            .then((data) => {setSection(data[0])})
            .catch(console.error)

        window.addEventListener('resize', setDimension);
    
        return(() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize]);

    if (!section) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.how-we-do-it.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {
        let backgroundElement = document.querySelector('.block.how-we-do-it .background');
        if (backgroundElement) {
            let background = backgroundElement.getAttribute('data-background');
            backgroundElement.style.background = 'url(' + background + ')';
        }

        
        

        if (window.innerWidth > 767) {
    
            let section = document.querySelector('.block.how-we-do-it');
    
            if (section) {
                let wrapper = section.querySelector('.wrapper-sections'),
                    variable = screenSize.dynamicWidth - screenSize.dynamicHeight,
                    width = wrapper.offsetWidth;
                section.style.height = (width - variable) + 'px';
    
                const handleScroll = event => {
                    let sectionRect = section.getBoundingClientRect();
    
                    if (sectionRect.top < 0) {
                        wrapper.style.position = 'fixed';
                        wrapper.style.top = 0;
                        wrapper.style.bottom = 'unset';
                        wrapper.style.transform = 'translateX(' + sectionRect.top + 'px)';
                    }
                    
                    if (sectionRect.top > 0) {
                        wrapper.style.position = 'absolute';
                        wrapper.style.top = 0;
                        wrapper.style.bottom = 'unset';
                        wrapper.style.transform = 'translateX(0)';
                    }
                    
                    if ((sectionRect.bottom - window.innerHeight) < 0) {
                        wrapper.style.position = 'absolute';
                        wrapper.style.top = 'unset';
                        wrapper.style.bottom = 0;
                        wrapper.style.transform = 'translateX(-' + (width - window.innerWidth) + 'px)';
                    }
    
                };
                
                window.addEventListener('scroll', handleScroll);
            }
        }

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <section className='block how-we-do-it loading-component' id='como-lo-hacemos'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='wrapper-sections'>
                        <div className='item item-one'>
                            <div className='content'>
                                <div className='flex-content'>
                                    <div className='image-wrapper'>
                                        <div className='image image-inview'>
                                            {(section.image1)
                                                ?
                                                    <img
                                                        src={urlFor(section.image1.asset._ref).url()}
                                                        alt='Imagen Uno'
                                                    />
                                                :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className='text-wrapper'>
                                        <div className='text text-inview'>
                                            <BlockContent
                                                blocks={section.text1}
                                                projectId='lohpph99'
                                                dataset='production'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item item-two'>
                            <div className='content'>
                                <div className='flex-content'>
                                    <div className='image-wrapper'>
                                        <div className='image image-inview'>
                                            {(section.sections[0])
                                                ?
                                                    <img
                                                        src={urlFor(section.sections[0].image.asset._ref).url()}
                                                        alt='Imagen Uno'
                                                    />
                                                :
                                                    null
                                            }
                                            <div className='index'>{(section.sections.indexOf(section.sections[0])+1)}.0</div>
                                        </div>
                                    </div>
                                    <div className='text-wrapper'>
                                        <div className='title text-inview'>{section.sections[0].text1}</div>
                                        <div className='text text-inview'>
                                            <BlockContent
                                                blocks={section.sections[0].text2}
                                                projectId='lohpph99'
                                                dataset='production'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item item-three'>
                            <div className='content'>
                                <div className='image-wrapper'>
                                    <div className='image'>
                                        {(section.sections[1])
                                            ?
                                                <img
                                                    src={urlFor(section.sections[1].image.asset._ref).url()}
                                                    alt='Imagen Uno'
                                                />
                                            :
                                                null
                                        }
                                        <div className='index'>{(section.sections.indexOf(section.sections[1])+1)}.0</div>
                                    </div>
                                </div>
                                <div className='text-wrapper'>
                                    <div className='title'>{section.sections[1].text1}</div>
                                    <div className='text'>
                                        <BlockContent
                                            blocks={section.sections[1].text2}
                                            projectId='lohpph99'
                                            dataset='production'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item item-four'>
                            <div className='content'>
                                <div className='flex-content'>
                                    <div className='image-wrapper'>
                                        <div className='image'>
                                            {(section.sections[2])
                                                ?
                                                    <img
                                                        src={urlFor(section.sections[2].image.asset._ref).url()}
                                                        alt='Imagen Uno'
                                                    />
                                                :
                                                    null
                                            }
                                            <div className='index'>{(section.sections.indexOf(section.sections[2])+1)}.0</div>
                                        </div>
                                    </div>
                                    <div className='text-wrapper'>
                                        <div className='title'>{section.sections[2].text1}</div>
                                        <div className='text'>
                                            <BlockContent
                                                blocks={section.sections[2].text2}
                                                projectId='lohpph99'
                                                dataset='production'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item item-five'>
                            <div className='content'>
                                <div className='text-wrapper'>
                                    <div className='title'>{section.sections[3].text1}</div>
                                    <div className='text'>
                                        <BlockContent
                                            blocks={section.sections[3].text2}
                                            projectId='lohpph99'
                                            dataset='production'
                                        />
                                    </div>
                                </div>
                                <div className='image-wrapper'>
                                    <div className='image'>
                                        {(section.sections[3])
                                            ?
                                                <img
                                                    src={urlFor(section.sections[3].image.asset._ref).url()}
                                                    alt='Imagen Uno'
                                                />
                                            :
                                                null
                                        }
                                        <div className='index'>{(section.sections.indexOf(section.sections[3])+1)}.0</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='item item-six'>
                            <div className='scroll-element'></div>
                            <div className='content'>
                                <div className='text-wrapper'>
                                    <div
                                        className='background'
                                        data-background={urlFor(section.image2.asset._ref).url()}
                                    />
                                    <div className='text'>
                                        <BlockContent
                                            blocks={section.text2}
                                            projectId='lohpph99'
                                            dataset='production'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowWeDoIt;
import React, { useState, useEffect } from 'react'
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';

import '../design/styles/about-us-sections/purpose.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const PurposeAbout = () => {

    const [purpose, setPurpose] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "purposeAbout"]{
                text1,
                text2,
                image
            }`)
            .then((data) => setPurpose(data[0]))
            .catch(console.error)
    }, []);

    if (!purpose) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.purpose.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <ParallaxProvider>
            <section className='block purpose loading-component' id='proposito'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='content'>
                            <div className='image-wrapper hidden-xs'>
                                <div className='image image-waiting'>
                                    <Parallax speed={-10} translateY={[-30, 30]}>
                                        <img
                                            src={urlFor(purpose.image.asset._ref).url()}
                                            alt='Imagen Propósito'
                                        />
                                    </Parallax>
                                </div>
                            </div>
                            <div className='text-wrapper'>
                                <div className='text-one text-waiting'>
                                    <BlockContent
                                        blocks={purpose.text1}
                                        projectId='lohpph99'
                                        dataset='production'
                                    />
                                </div>
                                <div className='image-wrapper visible-xs'>
                                    <div className='image image-waiting'>
                                        <Parallax speed={-10} translateY={[-30, 30]}>
                                            <img
                                                src={urlFor(purpose.image.asset._ref).url()}
                                                alt='Imagen Propósito'
                                            />
                                        </Parallax>
                                    </div>
                                </div>
                                <div className='text-two text-inview'>
                                    <BlockContent
                                        blocks={purpose.text2}
                                        projectId='lohpph99'
                                        dataset='production'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ParallaxProvider>
    )
}

export default PurposeAbout;
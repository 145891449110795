import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { NavLink } from 'react-router-dom';
import PrevBtn from './PrevBtn';
import NextBtn from './NextBtn';
import useSingleEvent from '../customHooks/useSingleEvent';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const GridGallery = () => {

    let indexPrev = 0,
        indexNext = 0;

    let eventSlug = localStorage.getItem('currentEvento');

    const [event, setEvent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { slug } = useParams();
    const singleEvent = useSingleEvent(slug)

    useEffect(() => {
        sanityClient
            .fetch(`*[slug.current == "${eventSlug}"][0]{
                sections
            }`)
            .then((data) =>{
                setEvent(data)
                setIsLoading(false)
            })
            .catch(console.error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSlug]);

    useEffect(() => {
        let iframeContent = document.getElementById('iframe-content');

        if(iframeContent){
            iframeContent.innerHTML = singleEvent.video;
        }
    })

    if (!singleEvent) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.gallery-template.loading-component');
            if(component) {
                setTimeout(() => {
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);
    if(!isLoading){
        event.sections.map((sect, index) => {
            if (singleEvent._id === sect._ref) {
                indexPrev = index - 1;
                indexNext = index + 1;
            }
            return <div className='hidden'>Working</div>
        })
    }

    return (
        <ParallaxProvider>
            <section className='block gallery-template  loading-component'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title text-waiting'>{singleEvent.text1}</div>
                            { (singleEvent.text3) ? <div className='year text-waiting'>Año: {singleEvent.text3}</div> : null }
                            { (singleEvent.text4) ? <div className='location text-waiting'>Ubicación: {singleEvent.text4}</div> : null }
                            { (singleEvent.video) ? <div id='iframe-content'></div> : null}
                        </div>
                        <div className='content'>
                            {
                                (singleEvent.gallery && singleEvent.gallery.length >= 7)
                                    ?
                                        <div className='grid'>
                                            <div className='images-wrapper-one text-waiting'>
                                                <Parallax
                                                    speed={(window.innerWidth > 767) ? -10 : 0}
                                                    className='image-one'
                                                >
                                                    <img
                                                        src={urlFor(singleEvent.gallery[0].asset._ref).url()}
                                                        alt={singleEvent.text1}
                                                    />
                                                </Parallax>
                                                <Parallax
                                                    speed={(window.innerWidth > 767) ? -15 : 0}
                                                    className='image-two'
                                                >
                                                    <img
                                                        src={urlFor(singleEvent.gallery[1].asset._ref).url()}
                                                        alt={singleEvent.text1}
                                                    />
                                                </Parallax>
                                            </div>
                                            <div className='images-wrapper-two text-inview'>
                                                <div className='image-three'>
                                                    <img
                                                        src={urlFor(singleEvent.gallery[2].asset._ref).url()}
                                                        alt={singleEvent.text1}
                                                    />
                                                </div>
                                                <div className='image-four'>
                                                    <img
                                                        src={urlFor(singleEvent.gallery[3].asset._ref).url()}
                                                        alt={singleEvent.text1}
                                                    />
                                                </div>
                                            </div>
                                            <div className='image-five text-inview'>
                                                <img
                                                    src={urlFor(singleEvent.gallery[4].asset._ref).url()}
                                                    alt={singleEvent.text1}
                                                />
                                            </div>
                                            <div className='images-wrapper-three text-inview'>
                                                <div className='image-six'>
                                                    <img
                                                        src={urlFor(singleEvent.gallery[5].asset._ref).url()}
                                                        alt={singleEvent.text1}
                                                    />
                                                </div>
                                                <Parallax
                                                    speed={(window.innerWidth > 767) ? 15 : 0}
                                                    className='image-seven'
                                                >
                                                    <img
                                                        src={urlFor(singleEvent.gallery[6].asset._ref).url()}
                                                        alt={singleEvent.text1}
                                                    />
                                                </Parallax>
                                            </div>
                                            {
                                                (singleEvent.gallery[7] && singleEvent.gallery[8])
                                                ?
                                                    <div className='images-wrapper-four text-inview'>
                                                        <div className='image-eight'>
                                                            <img
                                                                src={urlFor(singleEvent.gallery[7].asset._ref).url()}
                                                                alt={singleEvent.text1}
                                                            />
                                                        </div>
                                                        <div className='image-nine'>
                                                            <img
                                                                src={urlFor(singleEvent.gallery[8].asset._ref).url()}
                                                                alt={singleEvent.text1}
                                                            />
                                                        </div>
                                                    </div>
                                                :
                                                    null
                                            }
                                            {
                                                (singleEvent.gallery[9] && singleEvent.gallery[10])
                                                    ?
                                                        <div className='images-wrapper-one text-waiting'>
                                                            <Parallax
                                                                speed={(window.innerWidth > 767) ? -10 : 0}
                                                                className='image-one'
                                                            >
                                                                <img
                                                                    src={urlFor(singleEvent.gallery[9].asset._ref).url()}
                                                                    alt={singleEvent.text1}
                                                                />
                                                            </Parallax>
                                                            <Parallax
                                                                speed={(window.innerWidth > 767) ? -15 : 0}
                                                                className='image-two'
                                                            >
                                                                <img
                                                                    src={urlFor(singleEvent.gallery[10].asset._ref).url()}
                                                                    alt={singleEvent.text1}
                                                                />
                                                            </Parallax>
                                                        </div>
                                                    :
                                                        null
                                            }
                                            {
                                                (singleEvent.gallery[11] && singleEvent.gallery[12])
                                                    ?
                                                        <div className='images-wrapper-two text-inview'>
                                                            <div className='image-three'>
                                                                <img
                                                                    src={urlFor(singleEvent.gallery[11].asset._ref).url()}
                                                                    alt={singleEvent.text1}
                                                                />
                                                            </div>
                                                            <div className='image-four'>
                                                                <img
                                                                    src={urlFor(singleEvent.gallery[12].asset._ref).url()}
                                                                    alt={singleEvent.text1}
                                                                />
                                                            </div>
                                                        </div>
                                                    :
                                                        null
                                            }
                                            {
                                                (singleEvent.gallery[13])
                                                    ?
                                                        <div className='image-five text-inview'>
                                                            <img
                                                                src={urlFor(singleEvent.gallery[13].asset._ref).url()}
                                                                alt={singleEvent.text1}
                                                            />
                                                        </div>
                                                    :
                                                        null
                                            }
                                            {
                                                (singleEvent.gallery[14] && singleEvent.gallery[15])
                                                    ?
                                                        <div className='images-wrapper-three text-inview'>
                                                            <div className='image-six'>
                                                                <img
                                                                    src={urlFor(singleEvent.gallery[14].asset._ref).url()}
                                                                    alt={singleEvent.text1}
                                                                />
                                                            </div>
                                                            <Parallax
                                                                speed={(window.innerWidth > 767) ? 15 : 0}
                                                                className='image-seven'
                                                            >
                                                                <img
                                                                    src={urlFor(singleEvent.gallery[15].asset._ref).url()}
                                                                    alt={singleEvent.text1}
                                                                />
                                                            </Parallax>
                                                        </div>
                                                    :
                                                        null
                                            }
                                        </div>
                                    :
                                        null
                            }
                            {
                                (event.sections)
                                    ?
                                        <div className='flex-content'>
                                            {
                                                (indexPrev > -1)
                                                    ?
                                                        <PrevBtn
                                                            elem_id={event.sections[indexPrev]._ref}
                                                        />
                                                    :
                                                        <PrevBtn
                                                            elem_id={event.sections[event.sections.length-1]._ref}
                                                        />
                                            }
                                            <NavLink
                                                className='current'
                                                to={'/eventos/social'}
                                            >
                                                Social
                                            </NavLink>
                                            {
                                                (indexNext < event.sections.length)
                                                    ?
                                                        <NextBtn
                                                            elem_id={event.sections[indexNext]._ref}
                                                        />
                                                    :
                                                        <NextBtn
                                                            elem_id={event.sections[0]._ref}
                                                        />
                                            }
                                        </div>
                                    :
                                        null
                            }
                        </div>
                    </div>
                </div>
            </section>
        </ParallaxProvider>
    )
} 

export default GridGallery;
import React, { useState, useEffect } from 'react'
import sanityClient from '../client';
import BlockContent from "@sanity/block-content-to-react";

import '../design/styles/about-us-sections/trajectory.scss';

const TrajectoryAbout = () => {

    const [trajectory, setTrajectory] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "trajectoryAbout"]{
                text1,
                text2,
                text3,
                image
            }`)
            .then((data) => setTrajectory(data[0]))
            .catch(console.error)
    }, []);

    if (!trajectory) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.trajectory.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return(
        <section className='block trajectory loading-component' id='trayectoria'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='header'>
                        <div className='title text-inview'>
                            <BlockContent
                                blocks={trajectory.text1}
                                projectId='lohpph99'
                                dataset='production'
                            />
                        </div>
                    </div>
                    <div className='content'>
                        <div className='flex-content'>
                            <div className='text-wrapper'>
                                <div className='text text-inview'>
                                    <BlockContent
                                        blocks={trajectory.text2}
                                        projectId='lohpph99'
                                        dataset='production'
                                    />
                                </div>
                            </div>
                            <div className='phrase-wrapper'>
                                <div className='phrase text-inview'>{trajectory.text3}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TrajectoryAbout;
import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import { ParallaxProvider, ParallaxBanner } from 'react-scroll-parallax';

import '../design/styles/foundations-sections/cover.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const CoverFoundations = () => {

    const [coverFoundations, setCoverFoundations] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "foundationsTemplate"]{
                text1,
                text2,
                "coverFoundationsImage": image.asset->url
            }`)
            .then((data) => {setCoverFoundations(data[0])})
            .catch(console.error)
    }, []);

    if (!coverFoundations) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.cover-foundations.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    setTimeout(() => {

        let backgroundElement = document.querySelector('.background');
        if (backgroundElement) {
            let background = backgroundElement.getAttribute('data-background');
            backgroundElement.style.background = 'url(' + background + ')';
        }

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <ParallaxProvider>
            <section className='block cover-foundations loading-component'>
                <ParallaxBanner
                    layers={[
                        {
                        image: urlFor(coverFoundations.coverFoundationsImage).url(),
                        speed: -20,
                        },
                    ]}
                    style={{ aspectRatio: '2 / 1' }}
                    className='background'
                    data-background={urlFor(coverFoundations.coverFoundationsImage).url()}
                />

                <div className='text-wrapper'>
                    <div className='text-one text-waiting'>
                        <BlockContent
                            blocks={coverFoundations.text1}
                            projectId='lohpph99'
                            dataset='production'
                        />
                    </div>
                    <div className='text-two text-waiting'>
                        <BlockContent
                            blocks={coverFoundations.text2}
                            projectId='lohpph99'
                            dataset='production'
                        />
                    </div>
                </div>
            </section>
        </ParallaxProvider>
    )
}

export default CoverFoundations;
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Navigation = () => {

    const [isActive, setIsActive] = useState(false)

    let actualLocation = window.location;
    
    const expandMenu = () => {
        if (window.innerWidth < 767) {
        let toggleBtn = document.querySelector('.toggle-btn'),
            listWrapper = document.querySelector('.list-wrapper'),
            navigation = document.querySelector('.navigation'),
            body = document.querySelector('body');

                if (listWrapper.classList.contains('list-expanded')) {
                    listWrapper.classList.remove('list-expanded');
                    toggleBtn.classList.remove('expanded');
                    navigation.classList.remove('expanded');
                    body.classList.remove('no-scroll');

                    setTimeout(() => {
                        navigation.classList.remove('menu-inview');
                    }, 750);
                } else {
                    navigation.classList.add('menu-inview');
                    listWrapper.classList.add('list-expanded');
                    toggleBtn.classList.add('expanded');
                    navigation.classList.add('expanded');
                    body.classList.add('no-scroll');
                }
        }
    }

    const changeColor = () => {
        let body = document.querySelector('body');

        if (body) {
            if(body.classList.contains('dark-theme')) {
                body.classList.remove('dark-theme');
            } else {
                body.classList.add('dark-theme')
            }
        }
    }

    const setBackground = (event) => {
        if(window.scrollY >= 80){
            setIsActive(true)
        } else {
            setIsActive(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', setBackground)
    })

    return (
        <nav className={`navigation ${isActive ? 'scrollTop' : ''} ${ actualLocation.pathname === '/' || actualLocation.pathname === '/nosotros' || actualLocation.pathname === '/fundaciones' || actualLocation.pathname === '/alianzas' ? 'back-transparent' : ''} ${actualLocation.pathname === '/eventos/social' || actualLocation.pathname.substring(0, 8) === '/galeria' || actualLocation.pathname.substring(0, 7) === '/aliado' ? "color-black" : ""}`}>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='content'>
                        {
                            (actualLocation.pathname !== '/')
                                ?
                                    <NavLink
                                        to='/'
                                        className='logo'
                                    />
                                :
                                    <HashLink
                                        smooth
                                        to='/#portada'
                                        className='logo'
                                    />
                        }
                        <div className='list-wrapper'>
                            <div className='flex-content'>
                                <div className='left-side'>
                                    <div
                                        className={actualLocation.pathname === "/eventos/social" ? "theme-btn-b" : "theme-btn"}
                                        onClick={changeColor}
                                    />
                                    <ul className='list'>
                                        <li className='opt'>
                                            {
                                                (actualLocation.pathname !== '/')
                                                ?
                                                    <NavLink
                                                        className={`link ${actualLocation.hash === "#lo-que-hacemos" ? "pathActive" : ""}`}
                                                        to='/?lo-que-hacemos'
                                                        onClick={() => expandMenu()}
                                                        
                                                    >
                                                        <span className='no-pointer-events'>L</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='invisible no-pointer-events'>·</span>
                                                        <span className='no-pointer-events'>q</span>
                                                        <span className='no-pointer-events'>u</span>
                                                        <span className='no-pointer-events'>e</span>
                                                        <span className='invisible no-pointer-events'>·</span>
                                                        <span className='no-pointer-events'>h</span>
                                                        <span className='no-pointer-events'>a</span>
                                                        <span className='no-pointer-events'>c</span>
                                                        <span className='no-pointer-events'>e</span>
                                                        <span className='no-pointer-events'>m</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='no-pointer-events'>s</span>
                                                    </NavLink>
                                                :
                                                    <HashLink
                                                        smooth
                                                        className={`link ${actualLocation.hash === "#lo-que-hacemos" ? "pathActive" : ""}`}
                                                        to='/#lo-que-hacemos'
                                                        onClick={() => expandMenu()}
                                                        
                                                    >
                                                        <span className='no-pointer-events'>L</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='invisible no-pointer-events'>·</span>
                                                        <span className='no-pointer-events'>q</span>
                                                        <span className='no-pointer-events'>u</span>
                                                        <span className='no-pointer-events'>e</span>
                                                        <span className='invisible no-pointer-events'>·</span>
                                                        <span>h</span>
                                                        <span className='no-pointer-events'>a</span>
                                                        <span className='no-pointer-events'>c</span>
                                                        <span className='no-pointer-events'>e</span>
                                                        <span className='no-pointer-events'>m</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='no-pointer-events'>s</span>
                                                    </HashLink>
                                            }
                                        </li>
                                        <li className='opt'>
                                            {
                                                (actualLocation.pathname !== '/')
                                                ?
                                                    <NavLink
                                                        className={`link ${actualLocation.hash === "#como-lo-hacemos" ? "pathActive" : ""}`}
                                                        to='/?como-lo-hacemos'
                                                        onClick={() => expandMenu()}
                                                        
                                                    >
                                                        <span className='no-pointer-events'>C</span>
                                                        <span className='no-pointer-events'>ó</span>
                                                        <span className='no-pointer-events'>m</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='invisible no-pointer-events'>·</span>
                                                        <span className='no-pointer-events'>l</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='invisible no-pointer-events'>·</span>
                                                        <span className='no-pointer-events'>h</span>
                                                        <span className='no-pointer-events'>a</span>
                                                        <span className='no-pointer-events'>c</span>
                                                        <span className='no-pointer-events'>e</span>
                                                        <span className='no-pointer-events'>m</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='no-pointer-events'>s</span>
                                                    </NavLink>
                                                :
                                                    <HashLink
                                                        smooth
                                                        className={`link ${actualLocation.hash === "#como-lo-hacemos" ? "pathActive" : ""}`}
                                                        to='/#como-lo-hacemos'
                                                        onClick={() => expandMenu()}
                                                        
                                                    >
                                                        <span className='no-pointer-events'>C</span>
                                                        <span className='no-pointer-events'>ó</span>
                                                        <span className='no-pointer-events'>m</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='invisible no-pointer-events'>·</span>
                                                        <span className='no-pointer-events'>l</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='invisible no-pointer-events'>·</span>
                                                        <span className='no-pointer-events'>h</span>
                                                        <span className='no-pointer-events'>a</span>
                                                        <span className='no-pointer-events'>c</span>
                                                        <span className='no-pointer-events'>e</span>
                                                        <span className='no-pointer-events'>m</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='no-pointer-events'>s</span>
                                                    </HashLink>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                <div className='right-side'>
                                    <ul className='list'>
                                        <li className='opt'>
                                            <NavLink
                                                className={`link ${actualLocation.pathname === "/alianzas" ? "pathActive" : ""}`}
                                                to='/alianzas'
                                                onClick={() => expandMenu()}
                                                
                                            >
                                                <span className='no-pointer-events'>A</span>
                                                <span className='no-pointer-events'>l</span>
                                                <span className='no-pointer-events'>i</span>
                                                <span className='no-pointer-events'>a</span>
                                                <span className='no-pointer-events'>d</span>
                                                <span className='no-pointer-events'>o</span>
                                                <span className='no-pointer-events'>s</span>
                                            </NavLink>
                                        </li>
                                        <li className='opt has-sublist'>
                                            <span
                                                className={`link ${actualLocation.pathname === "/eventos/social" ? "pathActive" : ""}`}
                                                
                                            >
                                                <span className='no-pointer-events'>P</span>
                                                <span className='no-pointer-events'>r</span>
                                                <span className='no-pointer-events'>o</span>
                                                <span className='no-pointer-events'>y</span>
                                                <span className='no-pointer-events'>e</span>
                                                <span className='no-pointer-events'>c</span>
                                                <span className='no-pointer-events'>t</span>
                                                <span className='no-pointer-events'>o</span>
                                                <span className='no-pointer-events'>s</span>
                                            </span>
                                            <ul className='sublist'>
                                                <li className='sub-opt'>
                                                    <NavLink 
                                                        className='sub-link'
                                                        to='/eventos/social'
                                                        onClick={() => expandMenu()}
                                                        
                                                    >
                                                        <span className='no-pointer-events'>S</span>
                                                        <span className='no-pointer-events'>o</span>
                                                        <span className='no-pointer-events'>c</span>
                                                        <span className='no-pointer-events'>i</span>
                                                        <span className='no-pointer-events'>a</span>
                                                        <span className='no-pointer-events'>l</span>
                                                    </NavLink>
                                                </li>
                                                <li className='sub-opt' style={{ display: 'none'}}>
                                                    <div 
                                                        className='sub-link'
                                                        
                                                    >
                                                        <span className='no-pointer-events'>E</span>
                                                        <span className='no-pointer-events'>m</span>
                                                        <span className='no-pointer-events'>p</span>
                                                        <span className='no-pointer-events'>r</span>
                                                        <span className='no-pointer-events'>e</span>
                                                        <span className='no-pointer-events'>s</span>
                                                        <span className='no-pointer-events'>a</span>
                                                        <span className='no-pointer-events'>r</span>
                                                        <span className='no-pointer-events'>i</span>
                                                        <span className='no-pointer-events'>a</span>
                                                        <span className='no-pointer-events'>l</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className='opt has-sublist'>
                                            <NavLink
                                                className={`link ${actualLocation.pathname === "/nosotros" ? "pathActive" : ""}`}
                                                to='/nosotros'
                                                onClick={() => expandMenu()}
                                            >
                                                <span className='no-pointer-events'>N</span>
                                                <span className='no-pointer-events'>o</span>
                                                <span className='no-pointer-events'>s</span>
                                                <span className='no-pointer-events'>o</span>
                                                <span className='no-pointer-events'>t</span>
                                                <span className='no-pointer-events'>r</span>
                                                <span className='no-pointer-events'>o</span>
                                                <span className='no-pointer-events'>s</span>
                                            </NavLink>
                                            <ul className='sublist' style={{display: "none"}}>
                                                <li className='sub-opt'>
                                                    {
                                                        (actualLocation.pathname !== '/nosotros')
                                                        ?
                                                            <NavLink
                                                                className='sub-link'
                                                                to='/nosotros'
                                                                onClick={() => expandMenu()}
                                                                
                                                            >
                                                                <span className='no-pointer-events'>P</span>
                                                                <span className='no-pointer-events'>r</span>
                                                                <span className='no-pointer-events'>o</span>
                                                                <span className='no-pointer-events'>p</span>
                                                                <span className='no-pointer-events'>ó</span>
                                                                <span className='no-pointer-events'>s</span>
                                                                <span className='no-pointer-events'>i</span>
                                                                <span className='no-pointer-events'>t</span>
                                                                <span className='no-pointer-events'>o</span>
                                                            </NavLink>
                                                        :
                                                            <HashLink
                                                                smooth
                                                                className='sub-link'
                                                                to='/nosotros#portada'
                                                                onClick={() => expandMenu()}
                                                                
                                                            >
                                                                <span className='no-pointer-events'>P</span>
                                                                <span className='no-pointer-events'>r</span>
                                                                <span className='no-pointer-events'>o</span>
                                                                <span className='no-pointer-events'>p</span>
                                                                <span className='no-pointer-events'>ó</span>
                                                                <span className='no-pointer-events'>s</span>
                                                                <span className='no-pointer-events'>i</span>
                                                                <span className='no-pointer-events'>t</span>
                                                                <span className='no-pointer-events'>o</span>
                                                            </HashLink>
                                                    }
                                                </li>
                                                <li className='sub-opt'>
                                                    {
                                                        (actualLocation.pathname !== '/nosotros')
                                                        ?
                                                            <NavLink
                                                                className='sub-link'
                                                                to='/nosotros?trayectoria'
                                                                onClick={() => expandMenu()}
                                                                
                                                            >
                                                                <span className='no-pointer-events'>T</span>
                                                                <span className='no-pointer-events'>r</span>
                                                                <span className='no-pointer-events'>a</span>
                                                                <span className='no-pointer-events'>y</span>
                                                                <span className='no-pointer-events'>e</span>
                                                                <span className='no-pointer-events'>c</span>
                                                                <span className='no-pointer-events'>t</span>
                                                                <span className='no-pointer-events'>o</span>
                                                                <span className='no-pointer-events'>r</span>
                                                                <span className='no-pointer-events'>i</span>
                                                                <span className='no-pointer-events'>a</span>
                                                            </NavLink>
                                                        :
                                                            <HashLink
                                                                smooth
                                                                className='sub-link'
                                                                to='/nosotros#trayectoria'
                                                                onClick={() => expandMenu()}
                                                                
                                                            >
                                                                <span className='no-pointer-events'>T</span>
                                                                <span className='no-pointer-events'>r</span>
                                                                <span className='no-pointer-events'>a</span>
                                                                <span className='no-pointer-events'>y</span>
                                                                <span className='no-pointer-events'>e</span>
                                                                <span className='no-pointer-events'>c</span>
                                                                <span className='no-pointer-events'>t</span>
                                                                <span className='no-pointer-events'>o</span>
                                                                <span className='no-pointer-events'>r</span>
                                                                <span className='no-pointer-events'>i</span>
                                                                <span className='no-pointer-events'>a</span>
                                                            </HashLink>
                                                    }
                                                </li>
                                                <li className='sub-opt'>
                                                    {
                                                        (actualLocation.pathname !== '/nosotros')
                                                        ?
                                                            <NavLink
                                                                className='sub-link'
                                                                to='/nosotros?fundaciones'
                                                                onClick={() => expandMenu()}
                                                                
                                                            >
                                                                <span className='no-pointer-events'>F</span>
                                                                <span className='no-pointer-events'>u</span>
                                                                <span className='no-pointer-events'>n</span>
                                                                <span className='no-pointer-events'>d</span>
                                                                <span className='no-pointer-events'>a</span>
                                                                <span className='no-pointer-events'>c</span>
                                                                <span className='no-pointer-events'>i</span>
                                                                <span className='no-pointer-events'>o</span>
                                                                <span className='no-pointer-events'>n</span>
                                                                <span className='no-pointer-events'>e</span>
                                                                <span className='no-pointer-events'>s</span>
                                                            </NavLink>
                                                        :
                                                            <HashLink
                                                                smooth
                                                                className='sub-link'
                                                                to='/nosotros#fundaciones'
                                                                onClick={() => expandMenu()}
                                                                
                                                            >
                                                                <span className='no-pointer-events'>F</span>
                                                                <span className='no-pointer-events'>u</span>
                                                                <span className='no-pointer-events'>n</span>
                                                                <span className='no-pointer-events'>d</span>
                                                                <span className='no-pointer-events'>a</span>
                                                                <span className='no-pointer-events'>c</span>
                                                                <span className='no-pointer-events'>i</span>
                                                                <span className='no-pointer-events'>o</span>
                                                                <span className='no-pointer-events'>n</span>
                                                                <span className='no-pointer-events'>e</span>
                                                                <span className='no-pointer-events'>s</span>
                                                            </HashLink>
                                                    }
                                                </li>
                                                <li className='sub-opt'>
                                                    {
                                                        (actualLocation.pathname !== '/nosotros')
                                                        ?
                                                            <NavLink
                                                                className='sub-link'
                                                                to='/nosotros?pilares'
                                                                onClick={() => expandMenu()}
                                                                
                                                            >
                                                                <span className='no-pointer-events'>P</span>
                                                                <span className='no-pointer-events'>i</span>
                                                                <span className='no-pointer-events'>l</span>
                                                                <span className='no-pointer-events'>a</span>
                                                                <span className='no-pointer-events'>r</span>
                                                                <span className='no-pointer-events'>e</span>
                                                                <span className='no-pointer-events'>s</span>
                                                            </NavLink>
                                                        :
                                                            <HashLink
                                                                smooth
                                                                className='sub-link'
                                                                to='/nosotros#pilares'
                                                                onClick={() => expandMenu()}
                                                                
                                                            >
                                                                <span className='no-pointer-events'>P</span>
                                                                <span className='no-pointer-events'>i</span>
                                                                <span className='no-pointer-events'>l</span>
                                                                <span className='no-pointer-events'>a</span>
                                                                <span className='no-pointer-events'>r</span>
                                                                <span className='no-pointer-events'>e</span>
                                                                <span className='no-pointer-events'>s</span>
                                                            </HashLink>
                                                    }
                                                </li>
                                            </ul>
                                        </li>
                                        <li className='opt'>
                                            <HashLink
                                                smooth
                                                className={`link ${actualLocation.hash === "#contacto" ? "pathActive" : ""}`}
                                                to='#contacto'
                                                onClick={() => expandMenu()}
                                                
                                            >
                                                <span className='no-pointer-events'>C</span>
                                                <span className='no-pointer-events'>o</span>
                                                <span className='no-pointer-events'>n</span>
                                                <span className='no-pointer-events'>t</span>
                                                <span className='no-pointer-events'>a</span>
                                                <span className='no-pointer-events'>c</span>
                                                <span className='no-pointer-events'>t</span>
                                                <span className='no-pointer-events'>o</span>
                                            </HashLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <button className="toggle-btn visible-xs toggle-btn-to-expand" aria-label="Center Align" onClick={() => expandMenu()}>
                            <span className="bar"></span>
                            <span className="bar"></span>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navigation;
import '../design/styles/components/loader.scss';
import logo from '../design/imgs/casablanca-b.svg';
import video from '../design/imgs/casablanca-intro-new.mp4';
import { useEffect } from 'react';

const Loader = () => {

    setTimeout(() => {
        let logo = document.querySelector('.loader .logo'),
            background = document.querySelector('.loader .background'),
            firstSpan = document.querySelector('.loader .one'),
            secondSpan = document.querySelector('.loader .two'),
            thirdSpan = document.querySelector('.loader .three'),
            forthSpan = document.querySelector('.loader .four'),
            fifthSpan = document.querySelector('.loader .five'),
            sixthSpan = document.querySelector('.loader .six');

        logo.classList.add('inview');
        background.classList.add('inview');

        setTimeout(() => {
            firstSpan.classList.add('inview');

            setTimeout(() => {
                secondSpan.classList.add('inview');

                setTimeout(() => {
                    thirdSpan.classList.add('inview');

                    setTimeout(() => {
                        forthSpan.classList.add('inview');

                        setTimeout(() => {
                            fifthSpan.classList.add('inview');

                            setTimeout(() => {
                                sixthSpan.classList.add('inview');
                            }, 200);
                        }, 200);
                    }, 200);
                }, 200);
            }, 200);
        }, 200);
    }, 550);

    const hideLoader = (event) => {
        let body = document.querySelector('body');

        if (event.deltaY > 0) {
            body.classList.add('hide-loader');
            
            setTimeout(() => {
                //loader.remove();
                //localStorage.setItem('loader', true);
                body.classList.add('ready');
            }, 1500);
        }
    }

    const handleTouchStart = function() {
        let body = document.querySelector('body');
            body.classList.add('hide-loader');

        setTimeout(() => {
            //loader.remove();
            //localStorage.setItem('loader', true);
            body.classList.add('ready');
        }, 1500);
    }

    useEffect(() => {
        window.addEventListener('wheel', hideLoader)
    })

    return (
        <div className='loader' onTouchStart={handleTouchStart}>
            <div className='content'>
                <div className='background'>
                    <video src={video} loop muted autoPlay playsInline />
                </div>
                <div className='logo'>
                    <img
                        src={logo}
                        alt='Logotipo'
                    />
                </div>
                <div className='text'>
                    <span className='one'>La</span>
                    <span className='two'>geometría</span>
                    <span className='three'>secreta</span>
                    <span className='four'>de</span>
                    <span className='five'>lo</span>
                    <span className='six'>posible</span>
                </div>
            </div>
        </div>
    )
}

export default Loader;
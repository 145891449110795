import React from 'react';
import Footer from '../components/Footer';
import GridGallery from '../galleryTemplate/GridGallery';
import Navigation from '../components/Navigation';
import { motion, useIsPresent } from 'framer-motion';
//import { NavLink } from 'react-router-dom';

import '../design/styles/gallery-template/gallery.scss';

const Gallery = () => {
    const isPresent = useIsPresent();

    return (
        <div className='outer-wrapper'>
            <Navigation/>
            <GridGallery/>
            <Footer/>
            <motion.div
                initial={{ scaleY: 1 }}
                animate={{ scaleY: 0, transition: { duration: 1.5, ease: "circOut" } }}
                exit={{ scaleY: 1, transition: { duration: 1.5, ease: "circIn" } }}
                style={{ originY: isPresent ? 0 : 1 }}
                className="privacy-screen"
            />
        </div>
    )
}

export default Gallery;
import React, { useState, useEffect } from 'react';
import sanityClient from '../client';

import '../design/styles/foundations-sections/grid-foundations.scss';
import SingleFoundation from './SingleFoundation';

const GridFoundations = () => {

    const [foundations, setFoundations] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "foundationsTemplate"]{
                sections
            }`)
            .then((data) => setFoundations(data[0]))
            .catch(console.error)
    }, []);

    if (!foundations) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.grid-foundations.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    return (
        <section className='block grid-foundations loading-component'>
            <div className='holder'>
                <div className='container-fluid'>
                    <div className='content'>
                        <div className='text'>Por eso apoyamos diversas fundaciones que hacen de nuestra sociedad un lugar mejor.</div>
                        <div className='flex-content'>
                            {foundations.sections.map((foundation) => (
                                <SingleFoundation
                                    elem_id={foundation._ref}
                                    key={foundation._ref}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default GridFoundations;
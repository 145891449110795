import React, { useState, useEffect } from 'react';
import sanityClient from '../client';
import imageUrlBuilder from '@sanity/image-url';
import BlockContent from "@sanity/block-content-to-react";
import Slider from 'react-slick';

import '../design/styles/home-sections/products.scss';

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
    return builder.image(source)
}

const ProductsHome = () => {

    const [products, setProducts] = useState(null);

    useEffect(() => {
        sanityClient
            .fetch(`*[_type == "productsHome"]{
                text1,
                text2,
                sections
            }`)
            .then((data) => {setProducts(data[0])})
            .catch(console.error)
    }, []);

    if (!products) {return <div className="text-loading">Loading...</div>} else {
        setTimeout(() => {
            let component = document.querySelector('.block.products-home.loading-component');
            if(component) {
                setTimeout(() => {
                    
                    component.classList.add('ready');
                }, 550);
            }
        }, 250);
    }

    const settings = {
        arrows: false,
        cssEase: 'linear',
        dots: true,
        fade: true,
        infinite: false,
        speed: 500,
        swipe: false
    };

    setTimeout(() => {

        let section = document.querySelector('.block.products-home');
    
        if (section) {
            let allBtns = section.querySelectorAll('.text-holder .item'),
                allDots = section.querySelectorAll('.slick-dots button');
    
            allBtns.forEach(item => {
                let indexValue = item.getAttribute('data-index'),
                    index = parseInt(indexValue);
                if(index === 1) {
                    item.classList.add('active');
                }
                if (window.innerWidth > 767) {
                    item.addEventListener('mouseenter', () => {
                        allBtns.forEach(item => { item.classList.remove('active') })
                        item.classList.add('active');
                        if(index === 1) {
                            allDots[0].click();
                        }
                        if(index === 2) {
                            allDots[1].click();
                        }
                        if(index === 3) {
                            allDots[2].click();
                        }
                    })
                } else {
                    item.addEventListener('click', () => {
                        allBtns.forEach(item => { item.classList.remove('active') })
                        item.classList.add('active');
                        if(index === 1) {
                            allDots[0].click();
                        }
                        if(index === 2) {
                            allDots[1].click();
                        }
                        if(index === 3) {
                            allDots[2].click();
                        }
                    })
                }
            });
        }

        let allTextInview = document.querySelectorAll('.text-inview'),
            allImageInview = document.querySelectorAll('.image-inview');
        
        const handleScroll = event => {
            
            if (allTextInview) {
            allTextInview.forEach(textInview => {
                let textRect = textInview.getBoundingClientRect();
                if (textRect.top < (window.innerHeight/1.1)) {
                    textInview.classList.add('viewed');
                }
                })
            }
            
            if (allImageInview) {
            allImageInview.forEach(imageInview => {
                let imageRect = imageInview.getBoundingClientRect();
                if (imageRect.top < (window.innerHeight/1.3)) {
                    imageInview.classList.add('viewed');
                }
                })
            }
        };
        
        window.addEventListener('scroll', handleScroll);
        }, 1000);
    
    
    
        setTimeout(() => {
        let allTextWaiting = document.querySelectorAll('.text-waiting'),
            allImagesWaiting = document.querySelectorAll('.image-waiting');
            
        if (allTextWaiting) {
            allTextWaiting.forEach(textWaiting => {
                textWaiting.classList.add('viewed');
            })
        }
            
        if (allImagesWaiting) {
            allImagesWaiting.forEach(imageWaiting => {
                imageWaiting.classList.add('viewed');
            })
        }
    }, 550);

    return (
        <div className='wrapper-to-effect'>
            <section className='block products-home loading-component'>
                <div className='holder'>
                    <div className='container-fluid'>
                        <div className='header'>
                            <div className='title text-inview'>{products.text1}</div>
                        </div>
                        <div className='content'>
                            <div className='flex-content'>
                                <div className='text-holder'>
                                    <div className='item' data-index='1'>
                                        <div className='item-name text-inview'>{products.sections[0].text1}</div>
                                        <div className='image-holder'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(products.sections[0].image.asset._ref).url()}
                                                    alt={'Imagen' + products.sections[0].text1}
                                                />
                                            </div>
                                        </div>
                                        <div className='text-wrapper'>
                                            <div className='text-one text-inview'>
                                                <BlockContent
                                                    blocks={products.sections[0].text2}
                                                    projectId='lohpph99'
                                                    dataset='production'
                                                />
                                            </div>
                                            {
                                                (products.sections[0].text3)
                                                ?
                                                    <div className='text-two text-inview'>
                                                        <BlockContent
                                                            blocks={products.sections[0].text3}
                                                            projectId='lohpph99'
                                                            dataset='production'
                                                        />
                                                    </div>
                                                :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className='item' data-index='2'>
                                        <div className='item-name text-inview'>{products.sections[1].text1}</div>
                                        <div className='image-holder'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(products.sections[1].image.asset._ref).url()}
                                                    alt={'Imagen' + products.sections[1].text1}
                                                />
                                            </div>
                                        </div>
                                        <div className='text-wrapper'>
                                            <div className='text-one text-inview'>
                                                <BlockContent
                                                    blocks={products.sections[1].text2}
                                                    projectId='lohpph99'
                                                    dataset='production'
                                                />
                                            </div>
                                            {
                                                (products.sections[1].text3)
                                                ?
                                                    <div className='text-two text-inview'>
                                                        <BlockContent
                                                            blocks={products.sections[1].text3}
                                                            projectId='lohpph99'
                                                            dataset='production'
                                                        />
                                                    </div>
                                                :
                                                    null
                                            }
                                        </div>
                                    </div>
                                    <div className='item' data-index='3'>
                                        <div className='item-name text-inview'>{products.sections[2].text1}</div>
                                        <div className='image-holder'>
                                            <div className='image'>
                                                <img
                                                    src={urlFor(products.sections[2].image.asset._ref).url()}
                                                    alt={'Imagen' + products.sections[2].text1}
                                                />
                                            </div>
                                        </div>
                                        <div className='text-wrapper'>
                                            <div className='text-one text-inview'>
                                                <BlockContent
                                                    blocks={products.sections[2].text2}
                                                    projectId='lohpph99'
                                                    dataset='production'
                                                />
                                            </div>
                                            {
                                                (products.sections[2].text3)
                                                ?
                                                    <div className='text-two text-inview'>
                                                        <BlockContent
                                                            blocks={products.sections[2].text3}
                                                            projectId='lohpph99'
                                                            dataset='production'
                                                        />
                                                    </div>
                                                :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Slider {...settings} className="carrousel">
                                    {(products.sections.map((item, index) => (
                                        <div
                                            className='item'
                                            key={index}
                                        >
                                            <div className='image-wrapper'>
                                                <div className='image image-inview'>
                                                    <img
                                                        src={urlFor(item.image.asset._ref).url()}
                                                        alt={'Imagen' + item.text1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )))}
                                </Slider>
                            </div>
                            <div className='extra-text text-inview'>
                                <div className='text'>{products.text2}</div>
                                <a
                                    href='/files/CatalogoPhotobook_Final-comprimido.pdf'
                                    className='btn'
                                    target='_blank'
                                >
                                    <span className='btn-effect'>Descarga Nuestro Catálogo</span>
                                    <span className='btn-effect'>Descarga Nuestro Catálogo</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ProductsHome;